import { useState, useEffect, useMemo, useRef, useCallback } from "react";

export function useScroll(
  element?: Element,
  options: { isHorizontal: boolean } = { isHorizontal: false }
): {
  hasScrolledToEnd: boolean;
  scroll?: number;
  scrollDirection?: string;
  scrollingElement?: Element | null;
} {
  const scrollingElement = useMemo(() => {
    if (typeof window !== "undefined") {
      return element || document?.querySelector("html");
    }
  }, [element]);
  const [scroll, setScroll] = useState<number>(
    (options.isHorizontal ? scrollingElement?.scrollLeft : scrollingElement?.scrollTop) as number
  );
  const [scrollDirection, setScrollDirection] = useState<string>();
  const lastScrollRef = useRef<number>();
  lastScrollRef.current = scroll;

  const hasScrolledToEnd = options.isHorizontal
    ? scroll + (scrollingElement?.clientWidth || 0) === scrollingElement?.scrollWidth
    : scroll + (scrollingElement?.clientHeight || 0) === scrollingElement?.scrollHeight;

  const listener = useCallback(() => {
    if (scrollingElement) {
      const { scrollLeft, scrollTop } = scrollingElement;

      setScrollDirection(
        options.isHorizontal
          ? (lastScrollRef?.current ?? 0) < scrollLeft
            ? "right"
            : "left"
          : (lastScrollRef?.current ?? 0) < scrollTop
          ? "down"
          : "up"
      );
      setScroll(options.isHorizontal ? scrollLeft : scrollTop);
    }
  }, [options.isHorizontal, scrollingElement]);

  useEffect(() => {
    const el = element ? scrollingElement : window;

    if (typeof window !== "undefined") {
      el?.addEventListener("scroll", listener);
    }
    return () => {
      if (typeof window !== "undefined") {
        el?.removeEventListener("scroll", listener);
      }
    };
  }, [element, listener, scrollingElement]);

  return {
    hasScrolledToEnd,
    scroll,
    scrollDirection,
    scrollingElement
  };
}

export default useScroll;
