import styled, { css } from "styled-components";
import { DescriptionMedium } from "@diana-ui/typography";
import type { IAvatarProps } from "./Avatar";

/**
 * Component Wrapper element
 */
export const Wrapper = styled.div<IAvatarProps>(
  ({ backgroundColor, borderColor, theme }) => css`
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
    border-color: ${borderColor || theme.colors.grey.grey25};
  `
);

export const StyledDescriptionMedium = styled(DescriptionMedium)``;
