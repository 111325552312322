import styled from "styled-components";
import { Icon } from "@diana-ui/icon";
import { BodyHighlight } from "@diana-ui/typography";
import Avatar from "./Avatar";

/**
 * Component Image
 */
export const Image = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;

export const StyledAvatar = styled(Avatar)`
  border: none;
  border-radius: 50%;
`;

export const StyledBodyHighlight = styled(BodyHighlight)``;

export const StyledIcon = styled(Icon)``;
