import styled, { css } from "styled-components";
import { Checkbox } from "@ddm-design-system/checkbox";
import {
  BaseMultipleDropdownStyles,
  FilterMultipleDropdown as BaseFiltersMultipleDropdown,
  PopoverStyles
} from "@diana-ui/dropdown";
import { FontWeight } from "@ddm-design-system/tokens";
import { IDropdownProps } from "./FiltersMultipleDropdown.types";
import { DropdownStyles } from "./Dropdown";

export const StyledCheckbox = styled(Checkbox)``;

export const StyledItemText = styled.span``;

export const StyledFiltersDropdown = styled(
  BaseFiltersMultipleDropdown
)<IDropdownProps>(
  ({ theme, disabled, isLabelOnTop }) => css`
    ${isLabelOnTop &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}

    ${disabled &&
    css`
      ${DropdownStyles.TextWrapper} {
        color: ${theme.colors.grey.grey50};
      }

      ${DropdownStyles.StyledIcon} {
        stroke: ${theme.colors.grey.grey50};
      }

      ${BaseMultipleDropdownStyles.StyledLabel} {
        color: ${theme.colors.grey.grey50};
      }

      ${BaseMultipleDropdownStyles.StyledPopover} {
        ${BaseMultipleDropdownStyles.StyledLi} {
          color: ${theme.colors.grey.grey50};
        }
      }
    `}

    ${BaseMultipleDropdownStyles.StyledPopover} {
      background-color: ${theme.colors.white};
      border-radius: 4px 4px 0 0;
    }

    ${BaseMultipleDropdownStyles.StyledLabel} {
      font-size: 18px;
      font-family: ${theme.fontFamily};
      margin-right: ${theme.spaceUnit.xs};

      ${disabled &&
      css`
        color: ${theme.colors.grey.grey50};
      `}
    }

    ${BaseMultipleDropdownStyles.StyledUl} {
      border: 1px solid ${theme.colors.black};
      border-radius: 0 0 4px 4px;
      border-top: 1px solid ${theme.colors.grey.grey25};
      list-style: none;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
      overflow-y: auto;
      text-align: start;
      width: 100%;
    }

    ${BaseMultipleDropdownStyles.StyledDropdownheader} {
      min-width: 220px;
    }

    ${BaseMultipleDropdownStyles.StyledAllLi},
    ${BaseMultipleDropdownStyles.StyledLi} {
      background-color: ${theme.colors.white};
      cursor: pointer;
      padding: ${theme.spaceUnit.sm};
      height: 24px;
      display: flex;
      align-items: center;

      :hover {
        background-color: ${theme.colors.grey.grey25};
      }

      :last-child {
        border-radius: 0 0 4px 4px;
      }

      &.selected {
        font-weight: ${FontWeight.BOLD};
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      > * {
        width: 100%;
      }
    }

    ${BaseMultipleDropdownStyles.StyledItemText} {
      display: block;
      font-size: 18px;
      font-family: ${theme.fontFamily};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${PopoverStyles.Container} {
      &.top {
        padding-top: 0;
        margin-top: 1px;
      }
    }

    ${PopoverStyles.HeaderWrapper} {
      &.top {
        ${DropdownStyles.TextWrapper} {
          &.open {
            border-top: 0;
            border-bottom: 1px solid;
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }

    ${PopoverStyles.StyledPopover} {
      &.top {
        ${BaseMultipleDropdownStyles.StyledUl} {
          border-radius: 4px 4px 0 0;
          border-top: 1px solid ${theme.colors.black};
          border-bottom: 1px solid ${theme.colors.grey.grey25};

          ${BaseMultipleDropdownStyles.StyledLi} {
            :last-child {
              border-radius: 0;
            }

            :first-child {
              border-radius: 4px 4px 0 0;
            }
          }
        }
      }
    }
  `
);
