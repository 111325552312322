import styled, { css } from "styled-components";
import {
  TextInput as BaseTextInput,
  TextInputStyles
} from "@diana-ui/textinput";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const baseTextInputStylesheet = ({
  theme,
  label,
  disabled,
  hasError,
  prefixIcon
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => css`
  box-sizing: border-box;
  border: 1px solid ${theme.colors.grey.grey50};
  border-radius: 10px;
  margin-left: 0;
  margin-right: 0;
  height: 44px;
  padding: ${theme.spaceUnit.sm};

  &.focus,
  :hover {
    border-color: ${theme.colors.black};
  }

  ${TextInputStyles.InputContainer} {
    flex: 1;
  }

  ${TextInputStyles.LabelContainer} {
    height: 42px;
    width: calc(100% - ${theme.spaceUnit.xs});
    left: ${theme.spaceUnit.xs};
  }

  ${TextInputStyles.Legend} {
    height: 2px;
    text-overflow: ellipsis;

    @media screen and (max-width: 700px) {
      height: 3px;
    }

    ${!label &&
    css`
      padding: 0;
    `}
  }

  ${TextInputStyles.TextLabel} {
    text-overflow: ellipsis;
  }

  ${TextInputStyles.SuffixWrapper} {
    display: flex;
  }

  ${TextInputStyles.PrefixWrapper} {
    display: flex;
  }

  ${disabled &&
  css`
    background-color: ${theme.colors.grey.grey25};
    border-color: ${theme.colors.grey.grey25};

    &.focus,
    :hover {
      border-color: ${theme.colors.grey.grey25};
    }

    ${TextInputStyles.TextLabel} {
      color: ${theme.colors.grey.grey100};
    }

    ${TextInputStyles.Input} {
      background-color: ${theme.colors.grey.grey25};
    }
  `}

  ${hasError &&
  css`
    border-color: ${theme.colors.alert.alert100};
  `}

${prefixIcon &&
  css`
    ${TextInputStyles.TextLabel} {
      padding-left: ${theme.spaceUnit.xl};
    }
  `}

  &.active,
  &.focus {
    ${TextInputStyles.TextLabel} {
      z-index: 2;
      padding-left: ${theme.spaceUnit.xxs};
    }
  }

  ${TextInputStyles.Input} {
    background-color: transparent;
    &:-webkit-autofill-selected {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
        inset 0 0 0 100px ${theme.colors.white};
    }
    &:-webkit-autofill {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
        inset 0 0 0 100px ${theme.colors.white};
      z-index: 1;
    }
    &:-webkit-autofill:hover {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
        inset 0 0 0 100px ${theme.colors.white};
      z-index: 1;
    }
    &:-webkit-autofill:focus {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0),
        inset 0 0 0 100px ${theme.colors.white};
      z-index: 1;
    }
  }
`;

const TextInput = styled(BaseTextInput)(baseTextInputStylesheet);

TextInput.displayName = "TextInput";

export { TextInputStyles, baseTextInputStylesheet };

export default TextInput;
