import styled, { css } from "styled-components";
import theming from "styled-theming";
import BaseChip from "./BaseChip";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const statusChipVariations: any = theming.variants("mode", "type", {
  default: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    light: ({ theme, selected }: any) => css`
      border-color: ${theme.colors.grey.grey100};

      ${selected &&
      css`
        background-color: ${theme.colors.grey.grey25};
        border-color: ${theme.colors.grey.grey25};
      `}
    `
  },
  success: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    light: ({ theme, selected }: any) => css`
      border-color: ${theme.colors.success.success100};

      ${selected &&
      css`
        background-color: ${theme.colors.success.success25};
        border-color: ${theme.colors.success.success25};
      `}
    `
  },
  warning: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    light: ({ theme, selected }: any) => css`
      border-color: ${theme.colors.warning.warning100};

      ${selected &&
      css`
        background-color: ${theme.colors.warning.warning25};
        border-color: ${theme.colors.warning.warning25};
      `}
    `
  },
  danger: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    light: ({ theme, selected }: any) => css`
      border-color: ${theme.colors.alert.alert100};

      ${selected &&
      css`
        background-color: ${theme.colors.alert.alert25};
        border-color: ${theme.colors.alert.alert25};
      `}
    `
  }
});

export const AlteredBaseChip = styled(BaseChip)(
  () => css`
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid;

    ${statusChipVariations}
  `
);
