import styled, { css } from "styled-components";
import {
  InputSpinner as BaseInputSpinner,
  InputSpinnerStyles
} from "@diana-ui/input-spinner";

const InputSpinner = styled(BaseInputSpinner)(
  ({ theme, disabled }) => css`
    box-sizing: border-box;
    display: flex;
    margin: 0px;

    ${InputSpinnerStyles.ButtonMax},
    ${InputSpinnerStyles.ButtonMin} {
      border: 1px solid ${theme.colors.black};
      width: 40px;
      height: 40px;
      outline: none;
      cursor: pointer;
      background-color: ${theme.colors.white};
      padding: 0px;

      ${disabled &&
      css`
        cursor: initial;
        border-color: ${theme.colors.grey.grey50};
        color: ${theme.colors.grey.grey50};
      `}
    }

    ${InputSpinnerStyles.ButtonMax} {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    ${InputSpinnerStyles.ButtonMin} {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    ${InputSpinnerStyles.Value} {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: textfield !important;
      border-radius: 0;
      border: none;
      outline: none;
      border-top: 1px solid ${theme.colors.grey.grey50};
      border-bottom: 1px solid ${theme.colors.grey.grey50};
      display: flex;
      padding: ${theme.spaceUnit.xxs} ${theme.spaceUnit.md};
      align-items: center;
      justify-content: center;
      color: ${theme.colors.grey.grey100};
      min-width: 22px;
      text-align: center;
      ${theme.typography.body};
      line-height: 30px;
      margin-top: 0px;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0px;
      }
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0px;
      }
      ${disabled &&
      css`
        background-color: ${theme.colors.grey.grey25};
        border-color: ${theme.colors.grey.grey25};
      `}

      ${!disabled &&
      css`
        &:focus {
          border-color: ${theme.colors.black};
          color: ${theme.colors.black};
        }
        &:active {
          border-color: ${theme.colors.black};
          color: ${theme.colors.black};
        }
      `}
    }
    .focused {
      ${InputSpinnerStyles.Value} {
        ${!disabled &&
        css`
          border-color: ${theme.colors.black};
          color: ${theme.colors.black};
        `}
      }
    }
  `
);

export { InputSpinnerStyles };

export default InputSpinner;
