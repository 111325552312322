import styled, { css } from "styled-components";
import { Icon } from "@diana-ui/icon";
import type { ICheckboxProps } from "./Checkbox";

export const Container = styled.div<ICheckboxProps>(
  ({ theme, disabled }) => css`
    display: flex;
    flex-direction: row;
    outline-style: none;
    user-select: none;
    cursor: pointer;

    :hover .icon:not(.disabled):not(.checked):not(.show-as-checked) {
      opacity: 1;
      stroke: ${theme.colors.grey.grey100};
    }

    ${disabled &&
    css`
      cursor: initial;
    `}
  `
);

export const IconContainer = styled.div(
  ({ theme }) => css`
    width: ${theme.spaceUnit.md};
    height: ${theme.spaceUnit.md};
    border: 1px solid;
    border-color: ${theme.colors.black};
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
    background-color: ${theme.colors.white};
    transition: 0.3s background-color 0.3s border-color;
    margin-right: ${theme.spaceUnit.xs};
    display: flex;
    align-items: center;
    justify-content: center;

    &.checked {
      background-color: ${theme.colors.black};
    }
    &.disabled {
      background-color: ${theme.colors.grey.grey25};
      border-color: ${theme.colors.grey.grey100};
    }
  `
);

export const CheckboxText = styled.label(
  ({ theme }) => css`
    cursor: inherit;
    ${theme.typography.body}
  `
);

export const StyledCheckedIcon = styled(Icon)<ICheckboxProps>(
  ({ theme, showAsChecked, checked, disabled }) => css`
    opacity: 0;
    stroke: ${theme.colors.black};
    transition: 0.3s stroke 0.3s opacity;

    ${showAsChecked &&
    css`
      opacity: 1;
      stroke: ${theme.colors.black};
    `}

    ${checked &&
    css`
      opacity: 1;
      stroke: ${theme.colors.white};
    `}

      ${disabled &&
    css`
      stroke: ${theme.colors.grey.grey100};
    `}
  `
);

export const Input = styled.input``;
