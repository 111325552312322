import styled, { css } from "styled-components";
import { Modal } from "@diana-ui/modal";
import { ModalBodyStyles } from "./ModalBody";

export const StyledModal = styled(Modal)(
  ({ theme, isBottomSheet }) => css`
    ${isBottomSheet &&
    css`
      max-height: 90%;

      ${ModalBodyStyles.Body} {
        overflow: auto;
      }
    `}
  `
);
