import { Popover } from "@diana-ui/popover";
import styled, { css } from "styled-components";

/**
 * Component Ul
 */
export const List = styled.ul(
  () => css`
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
  `
);

export const StyledPopover = styled(Popover)``;
