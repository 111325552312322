import styled, { css } from "styled-components";
import { Divider } from "@ddm-design-system/divider";

export const DividerWrapper = styled.div(
  ({ theme }) => css`
    padding: 0px ${theme.spaceUnit.lg};
  `
);

export const StyledDivider = styled(Divider)``;
