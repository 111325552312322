import React, { createRef, forwardRef, ReactElement } from "react";
import { FiltersMultipleDropdownStyles as BaseFiltersMultipleDropdownStyles } from "@diana-ui/dropdown";
import type { IPopoverRef } from "@diana-ui/dropdown";
import { DropdownStyles, ICON_SIZE_PX } from "./Dropdown";
import * as FiltersMultipleDropdownStyles from "./FiltersMultipleDropdown.style";
import { IDropdownProps } from "./FiltersMultipleDropdown.types";

const FiltersMultipleDropdown = forwardRef<IPopoverRef, IDropdownProps>(
  (
    {
      disabled,
      items,
      placeholder = "\u00A0", // non blocking space to force header line height
      selectAllText,
      selectedItems,
      text,
      isLabelOnTop,
      ...props
    },
    ref = createRef()
  ) => {
    const isDisabled = disabled || items.length === 0;

    const renderCustomHeader =
      (headerText?: string) =>
      (isOpen: boolean): ReactElement =>
        (
          <DropdownStyles.TextWrapper
            className={[isOpen && "open", isDisabled && "disabled"]
              .filter(Boolean)
              .join(" ")}
          >
            <DropdownStyles.Text>{headerText}</DropdownStyles.Text>
            <DropdownStyles.StyledIcon
              className={[isOpen && "open", isDisabled && "disabled"]
                .filter(Boolean)
                .join(" ")}
              name="ChevronDown"
              size={ICON_SIZE_PX}
            />
          </DropdownStyles.TextWrapper>
        );

    const renderHeader = renderCustomHeader(
      text ??
        (selectedItems.length
          ? selectedItems
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((i: any) => i.text)
              .sort()
              .join(", ")
          : placeholder)
    );

    const renderAllSelectedHeader = renderCustomHeader(selectAllText);

    const selectAllItem = (checked: boolean): ReactElement => (
      <FiltersMultipleDropdownStyles.StyledCheckbox
        checked={checked}
        disabled={disabled}
      >
        {selectAllText ?? "Select all"}
      </FiltersMultipleDropdownStyles.StyledCheckbox>
    );

    const renderItem = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item: any,
      isSelected: boolean,
      isAllSelected: boolean
    ): ReactElement => {
      return (
        <FiltersMultipleDropdownStyles.StyledCheckbox
          checked={!isAllSelected && isSelected}
          showAsChecked={isAllSelected && !item.disabled}
          disabled={disabled}
        >
          <FiltersMultipleDropdownStyles.StyledItemText>
            {item.text}
          </FiltersMultipleDropdownStyles.StyledItemText>
        </FiltersMultipleDropdownStyles.StyledCheckbox>
      );
    };

    return (
      <FiltersMultipleDropdownStyles.StyledFiltersDropdown
        disabled={isDisabled}
        isLabelOnTop={isLabelOnTop}
        items={items}
        renderHeader={renderHeader}
        renderAllSelectedHeader={renderAllSelectedHeader}
        renderItem={renderItem}
        selectAllItem={selectAllItem}
        selectedItems={selectedItems}
        {...(props as any)}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
      />
    );
  }
);

export { FiltersMultipleDropdownStyles, BaseFiltersMultipleDropdownStyles };

export default FiltersMultipleDropdown;
