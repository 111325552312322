import styled, { css } from "styled-components";
import theming from "styled-theming";
import {
  Avatar as BaseAvatar,
  IAvatarProps,
  AvatarStyles
} from "@diana-ui/avatar";

const AVATAR_SIZE_PX = 48;

const xsSize = css`
  width: 24px;
  height: 24px;
  border-radius: 2px;
`;

const mdSize = css`
  height: ${AVATAR_SIZE_PX}px;
  width: ${AVATAR_SIZE_PX}px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sizeStyle = theming.variants<any, any>("mode", "size", {
  xs: {
    light: xsSize,
    dark: xsSize
  },
  md: {
    light: mdSize,
    dark: mdSize
  }
});

const Avatar = styled(BaseAvatar).attrs<IAvatarProps>(({ size }) => ({
  size: size || "md"
}))<IAvatarProps>(
  ({ theme, size }) => css`
    border-color: ${theme.colors.grey.greenish};
    border-style: solid;
    box-sizing: border-box;
    flex-shrink: 0;

    ${sizeStyle}

    ${typeof size === "number" &&
    css`
      height: ${size}px;
      width: ${size}px;
    `}
  `
);

Avatar.displayName = "Avatar";

export { AvatarStyles };

export default Avatar;
