import React from "react";
import styled, { css } from "styled-components";
import {
  ModalHeader as BaseModalHeader,
  ModalHeaderStyles as BaseModalHeaderStyles,
  IModalHeaderProps as IBaseModalHeaderProps
} from "@diana-ui/modal";
import * as ModalHeaderStyles from "./ModalHeader.style";

export interface IModalHeaderProps extends IBaseModalHeaderProps {
  showDivider?: boolean;
}

const StyledBaseModalHeader = styled(BaseModalHeader)(
  ({ theme }) => css`
    padding: ${theme.spaceUnit.lg};

    ${BaseModalHeaderStyles.CloseIcon} {
      margin-top: ${theme.spaceUnit.xxs};
    }

    ${BaseModalHeaderStyles.StyledDescription} {
      color: ${theme.colors.grey.grey100};
    }

    ${BaseModalHeaderStyles.StyledIcon} {
      display: flex;
      justify-content: center;
    }

    ${BaseModalHeaderStyles.TitleWrapper} {
      align-items: flex-start;
    }
  `
);

const ModalHeader: React.FC<IModalHeaderProps> = ({
  showDivider,
  ref,
  ...props
}) => (
  <>
    <StyledBaseModalHeader {...props} />
    {showDivider && (
      <ModalHeaderStyles.DividerWrapper>
        <ModalHeaderStyles.StyledDivider />
      </ModalHeaderStyles.DividerWrapper>
    )}
  </>
);

ModalHeader.displayName = "ModalHeader";

export { BaseModalHeaderStyles, ModalHeaderStyles };

export default ModalHeader;
