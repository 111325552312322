import styled, { css } from "styled-components";
import {
  InputSpinner as BaseInputSpinner,
  InputSpinnerStyles
} from "@diana-ui/input-spinner";

export default styled(BaseInputSpinner).attrs(() => ({ useIcons: true }))(
  ({ theme, disabled }) => css`
    box-sizing: border-box;
    display: flex;
    margin: 0px;

    ${InputSpinnerStyles.ButtonMax},
    ${InputSpinnerStyles.ButtonMin} {
      border: none;
      width: 26px;
      height: 26px;
      outline: none;
      cursor: pointer;
      background-color: ${theme.colors.white};
      padding: 0px;

      &:hover {
        opacity: 0.6;
      }

      ${disabled &&
      css`
        cursor: initial;
        opacity: 0.5;
      `}
    }

    ${InputSpinnerStyles.ButtonMaxIcon},
    ${InputSpinnerStyles.ButtonMinIcon} {
      width: 100%;
      height: 100%;
    }

    ${InputSpinnerStyles.Value} {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: textfield !important;
      border-radius: 0;
      border: none;
      outline: none;
      display: flex;
      padding: 0px ${theme.spaceUnit.md};
      align-items: center;
      justify-content: center;
      color: ${theme.colors.grey.grey100};
      min-width: 26px;
      text-align: center;
      ${theme.typography.sectionTitle};
      margin-top: 0px;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0px;
      }
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0px;
      }
      ${disabled &&
      css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.grey.grey50};
      `}

      ${!disabled &&
      css`
        &:focus {
          color: ${theme.colors.black};
        }
        &:active {
          color: ${theme.colors.black};
        }
      `}
    }
    .focused {
      ${InputSpinnerStyles.Value} {
        ${!disabled &&
        css`
          color: ${theme.colors.black};
        `}
      }
    }
  `
);
