import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";
import { LabelMedium } from "@ddm-design-system/typography";

export const StyledLabelMedium = styled(LabelMedium)(
  ({ theme }) => css`
    color: ${theme.colors.grey.grey100};
    margin-top: ${theme.spaceUnit.xs};
  `
);

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    fill: ${theme.colors.grey.grey100};
    color: ${theme.colors.grey.grey100};
  `
);
