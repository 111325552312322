import { SectionTitle } from "@diana-ui/typography";
import styled, { css } from "styled-components";

/**
 * Component Li
 */
export const TabLi = styled.li(
  ({ theme }) => css`
    box-sizing: border-box;
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    margin: 0px ${theme.spaceUnit.xs};

    &:first-child {
      margin-left: 0;
    }

    &::last-child {
      margin-right: 0;
    }

    &.disabled {
      cursor: default;
    }
  `
);

export const StyledLabel = styled(SectionTitle)(
  () => css`
    white-space: nowrap;
  `
);

export const TabBorder = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.black};
  `
);
