import styled, { css } from "styled-components";

export const Overlay = styled.div(
  ({ theme }) => css`
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: ${theme.colors.background.overlay};
    z-index: 24;
    top: 0;
    left: 0;
    cursor: pointer;
  `
);

export const StyledSidebar = styled.div(
  () => css`
    height: 100%;
    z-index: 25;

    &.animate {
      transition: 0.3s all;
    }

    &.side.left,
    &.side.right {
      overflow: hidden;
      width: 0;
    }

    &.side.left.open,
    &.side.right.open {
      overflow: initial;
      width: 100%;
    }

    &.side.bottom,
    &.side.top {
      overflow: hidden;
      height: 0;
    }

    &.side.bottom.open,
    &.side.top.open {
      overflow: initial;
      height: auto;
    }

    &.over {
      position: absolute;
      top: 0;
    }

    &.over.left {
      transform: translateX(-100%);
    }

    &.over.right {
      right: 0;
      transform: translateX(100%);
    }

    &.over.right.open,
    &.over.left.open {
      transform: translateX(0);
    }

    &.over.bottom {
      bottom: 0;
      height: unset;
      left: 0;
      right: 0;
      top: unset;
      transform: translateY(100%);
    }

    &.over.top {
      height: unset;
      left: 0;
      right: 0;
      transform: translateY(-100%);
    }

    &.over.bottom.open,
    &.over.top.open {
      transform: translateY(0);
    }
  `
);
