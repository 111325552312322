import { HTMLAttributes } from "react";
import styled from "styled-components";

export type ITitleProps = HTMLAttributes<HTMLHeadingElement>;
export type IProps = HTMLAttributes<HTMLSpanElement>;

export const PageTitle = styled.h1`
  ${props => props.theme.typography.pageTitle}
`;
export const Subtitle = styled.h2`
  ${props => props.theme.typography.subtitle}
`;
export const SectionTitle = styled.span`
  ${props => props.theme.typography.sectionTitle}
`;
export const ButtonText = styled.span`
  ${props => props.theme.typography.buttonText}
`;
export const BodyHighlight = styled.span`
  ${props => props.theme.typography.bodyHighlight}
`;
export const Body = styled.span`
  ${props => props.theme.typography.body}
`;
export const DescriptionMedium = styled.span`
  ${props => props.theme.typography.descriptionMedium}
`;
export const Description = styled.span`
  ${props => props.theme.typography.description}
`;
export const LabelMedium = styled.span`
  ${props => props.theme.typography.labelMedium}
`;
export const Label = styled.span`
  ${props => props.theme.typography.label}
`;
export const NotificationsNumbers = styled.span`
  ${props => props.theme.typography.notificationsNumbers}
`;
