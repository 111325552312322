import styled, { css } from "styled-components";
import { IImageAvatarProps } from "@diana-ui/avatar";
import ImageAvatar, { ImageAvatarStyles } from "./ImageAvatar";

export interface ITapAvatarProps extends IImageAvatarProps {
  selected?: boolean;
}

export default styled(ImageAvatar).attrs(({ borderColor, theme }) => ({
  icon: "Tap",
  borderColor: borderColor ?? theme.colors.grey.grey50
}))<ITapAvatarProps>(
  ({ theme, borderColor, selected }) => css`
    border: 1px dashed ${borderColor};

    ${ImageAvatarStyles.StyledIcon} {
      fill: ${theme.colors.grey.grey50};
      @media screen and (max-width: 700px) {
        height: 32px;
        width: 12px;
      }
    }

    ${selected &&
    css`
      border: 1px solid ${theme.colors.black};
    `}
  `
);
