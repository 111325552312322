import styled, { css } from "styled-components";
import { Icon } from "@diana-ui/icon";
import { Body } from "@diana-ui/typography";

export const StyledMenuItem = styled.li(
  () => css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `
);

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xs};
  `
);

export const StyledBody = styled(Body)``;
