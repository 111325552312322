import styled, { css } from "styled-components";

/**
 * Component Body
 */
export const Body = styled.section(
  () => css`
    flex: 1;
  `
);
