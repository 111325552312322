import React from "react";
import styled, { css } from "styled-components";
import { Menu as BaseMenu, IMenuProps as IBaseMenuProps } from "@diana-ui/menu";
import { IconNames } from "@ddm-design-system/icon";
import * as IconMenuStyles from "./IconMenu.style";
import { BaseMenuStyles } from "./Menu";

export interface IMenuProps extends IBaseMenuProps {
  icon?: IconNames;
}

export { IconMenuStyles };

export default styled(BaseMenu).attrs<IMenuProps>(({ icon }) => ({
  renderHeader: () => <IconMenuStyles.StyledIcon name={icon || "PopMenu"} />
}))<IMenuProps>(
  ({ theme }) => css`
    ${BaseMenuStyles.List} {
      border: 1px solid ${theme.colors.grey.greenish};
      border-radius: 10px;
    }
  `
);
