import styled, { css } from "styled-components";
import { ModalBody as BaseModalBody, ModalBodyStyles } from "@diana-ui/modal";

export { ModalBodyStyles };

export default styled(BaseModalBody)(
  ({ theme }) => css`
    padding: ${theme.spaceUnit.md} 0;
    margin: 0 ${theme.spaceUnit.lg};
    border-top: 1px solid ${theme.colors.grey.grey25};
  `
);
