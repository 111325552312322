import styled, { css } from "styled-components";
import {
  ChipInput as BaseChipInput,
  ChipInputStyles,
  IChipInputProps
} from "@diana-ui/chip";
import {
  ErrorTextInput,
  ErrorTextInputStyles,
  TextInputStyles
} from "@ddm-design-system/textinput";
import ChipList from "./ChipList";

const ChipInput = styled(BaseChipInput).attrs<IChipInputProps>({
  CustomErrorTextInputComponent: ErrorTextInput,
  CustomChipListComponent: ChipList
})(
  ({ theme }) => css`
    ${ErrorTextInputStyles.StyledBaseTextInput} {
      padding: ${theme.spaceUnit.xs};

      ${TextInputStyles.TextLabel} {
        padding-left: 0;
      }

      &.active,
      &.focus {
        ${TextInputStyles.TextLabel} {
          padding-left: 0;
        }
      }
    }
  `
);

ChipInput.displayName = "ChipInput";

export type { IChipInputProps };
export { ChipInputStyles };

export default ChipInput;
