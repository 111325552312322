import { FontWeight, EBreakpoints } from "@diana-ui/tokens";

export const spaceUnit = 16;
const fontSize = 18;
const fontFamily = "BrandonGrotesque";

const theme = {
  mode: "light",
  name: "ddm",
  fontFamily,
  fontSize,
  spaceUnit: {
    /**
     * 4px
     */
    xxs: `${spaceUnit * 0.25}px`,
    /**
     * 8px
     */
    xs: `${spaceUnit * 0.5}px`,
    /**
     * 12px
     */
    sm: `${spaceUnit * 0.75}px`,
    /**
     * 16px
     */
    md: `${spaceUnit}px`,
    /**
     * 24px
     */
    lg: `${spaceUnit * 1.5}px`,
    /**
     * 32px
     */
    xl: `${spaceUnit * 2}px`,
    /**
     * 48px
     */
    xxl: `${spaceUnit * 3}px`,
    /**
     * 64px
     */
    xxxl: `${spaceUnit * 4}px`
  },
  spacing: {
    squishsm: {
      top: `${spaceUnit / 4}px`,
      left: `${spaceUnit / 1.3}px`
    },
    squishmd: {
      top: `${spaceUnit / 2}px`,
      left: `${spaceUnit}px`
    },
    squishlg: {
      top: `${spaceUnit}px`,
      left: `${spaceUnit * 2}px`
    },
    stretchsm: {
      left: `${spaceUnit / 4}px`,
      top: `${spaceUnit / 1.3}px`
    },
    stretchmd: {
      left: `${spaceUnit / 2}px`,
      top: `${spaceUnit}px`
    },
    stretchlg: {
      left: `${spaceUnit}px`,
      top: `${spaceUnit * 2}px`
    }
  },
  typography: {
    pageTitle: {
      fontSize: "2.38rem",
      fontWeight: FontWeight.BOLD,
      lineHeight: "2.55rem",
      fontFamily,
      letterSpacing: ""
    },
    subtitle: {
      fontSize: "1.72rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "2rem",
      fontFamily,
      letterSpacing: ""
    },
    mainTitle: {
      fontSize: "1.44rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "1.78rem",
      fontFamily,
      letterSpacing: ""
    },
    sectionTitle: {
      fontSize: "1.16rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "1.55rem",
      fontFamily,
      letterSpacing: ""
    },
    buttonText: {
      fontSize: "1rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "1.33rem",
      fontFamily,
      letterSpacing: ""
    },
    bodyHighlight: {
      fontSize: "1rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "1.33rem",
      fontFamily: "BrandonGrotesque",
      letterSpacing: ""
    },
    body: {
      fontSize: "1rem",
      fontWeight: FontWeight.REGULAR,
      lineHeight: "1.33rem",
      fontFamily,
      letterSpacing: ""
    },
    descriptionMedium: {
      fontFamily,
      fontSize: "0.83rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "0.88rem",
      letterSpacing: ""
    },
    description: {
      fontFamily,
      fontSize: "0.83rem",
      fontWeight: FontWeight.REGULAR,
      lineHeight: "1.11rem",
      letterSpacing: ""
    },
    labelMedium: {
      fontFamily,
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "0.66rem",
      fontSize: "0.66rem",
      letterSpacing: ""
    },
    label: {
      fontFamily,
      fontWeight: FontWeight.REGULAR,
      lineHeight: "0.66rem",
      fontSize: "0.66rem",
      letterSpacing: ""
    },
    notificationsNumbers: {
      fontFamily,
      fontSize: "0.55rem",
      fontWeight: FontWeight.MEDIUM,
      lineHeight: "0.55rem",
      letterSpacing: ""
    }
  },
  breakpoints: {
    [EBreakpoints.MOBILE1]: 700,
    [EBreakpoints.MOBILE2]: 900,
    [EBreakpoints.TABLET1]: 1140
  }
};

export default theme;
