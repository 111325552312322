import styled, { css } from "styled-components";
import {
  Notification as BaseNotification,
  NotificationStyles
} from "@diana-ui/notification";

const Notification = styled(BaseNotification)(
  ({ theme }) => css`
    ${NotificationStyles.TextIcon} {
      margin-right: ${theme.spaceUnit.md};
    }

    ${NotificationStyles.StyledTitle} {
      margin-bottom: ${theme.spaceUnit.md};
    }

    ${NotificationStyles.TitleIcon} {
      margin-bottom: ${theme.spaceUnit.md};
      margin-right: ${theme.spaceUnit.md};
    }

    background-color: ${theme.colors.black};
    border-radius: 10px;
    box-sizing: border-box;
    color: ${theme.colors.white};
    padding: ${theme.spaceUnit.md};
    width: 400px;

    &:not(:first-child) {
      margin-top: ${theme.spaceUnit.xs};
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  `
);

export { NotificationStyles };

export default Notification;
