import styled, { css } from "styled-components";
import { Sidebar as BaseSidebar, SidebarStyles } from "@diana-ui/sidebar";

export { SidebarStyles };

export default styled(BaseSidebar)(
  ({ theme }) => css`
    width: 600px;

    @media (max-width: ${theme.breakpoints["mobile-1"]}px) {
      width: 100%;
    }
  `
);
