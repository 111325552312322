import React from "react";
import styled, { css } from "styled-components";
import { Icon, IconNames } from "@diana-ui/icon";

export interface ISplashProps {
  className?: string;
  infinite?: boolean;
  $infinite?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const animation = (theme: any) => css`
  animation: ${theme.animations.ripple} forwards alternate-reverse infinite;
`;

export const SplashIcon = styled(Icon)<ISplashProps>(
  ({ theme, $infinite }) => css`
    stroke: ${theme.colors.primary};
    stroke-opacity: 0.5;

    > path {
      opacity: 1;
      animation: ${theme.animations.ripple};
      animation-duration: 1.5s;
      transform-origin: 45% 40%;
      ${$infinite && animation(theme)};
    }

    > .dm-loading_svg__path1 {
      animation-delay: 0s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path2 {
      animation-delay: 0.05s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path3 {
      animation-delay: 0.1s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path4 {
      animation-delay: 0.15s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path5 {
      animation-delay: 0.2s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path6 {
      animation-delay: 0.25s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path7 {
      animation-delay: 0.3s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path8 {
      animation-delay: 0.35s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path9 {
      animation-delay: 0.4s;
      animation-duration: 1.5s;
    }

    > .dm-loading_svg__path10 {
      animation-delay: 0.45s;
      animation-duration: 1.5s;
    }
  `
);

export const Splash = styled.div(
  () => css`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `
);

const SplashLoading: React.FC<ISplashProps> = ({
  className,
  infinite = true
}) => (
  <Splash className={className}>
    <SplashIcon name={"DmLoading" as IconNames} $infinite={infinite} />
  </Splash>
);

export default SplashLoading;
