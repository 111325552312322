import styled, { css } from "styled-components";

export const StyledSVG = styled.svg(
  ({ theme }) => css`
    fill: none;
    vertical-align: middle;

    &.disabled {
      fill: ${theme.colors.grey.grey25};
    }
  `
);

export const StyledCircle = styled.circle(
  ({ theme }) => css`
    stroke-width: 1px;
    stroke: ${theme.colors.black};
  `
);

export const StyledInnerPath = styled.path(
  ({ theme }) => css`
    stroke-width: 6px;
    stroke-dasharray: 19px;
    stroke-dashoffset: 19px;

    &.checked {
      stroke-dashoffset: 38px;
      stroke: ${theme.colors.black};
    }
  `
);

export const StyledOuterPath = styled.path(
  ({ theme }) => css`
    stroke: ${theme.colors.black};
    stroke-width: 1px;
    stroke-dasharray: 57px;
    stroke-dashoffset: 57px;

    &.checked {
      stroke-dashoffset: 0;
    }

    &.disabled {
      stroke-dashoffset: 0;
      stroke: ${theme.colors.grey.grey50};
    }
  `
);
