import styled, { css } from "styled-components";

export const TabGroupUl = styled.ul(
  () => css`
    display: flex;
    margin: 0;
    padding: 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  `
);

export const TabPanel = styled.section``;
