import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import theming from "styled-theming";
import type { IStatusChipProps } from "@diana-ui/chip";
import { StatusChip as BaseStatusChip, StatusChipStyles } from "@diana-ui/chip";
import { Icon, IconNames } from "@ddm-design-system/icon";
import Chip from "./BaseChip";

const iconMap: { [type: string]: IconNames } = {
  default: "Ok",
  success: "Ok",
  warning: "Warning",
  danger: "Error",
  defective: "Error"
};

const iconType = ({
  theme
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: DefaultTheme | any;
}) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theming.variants<"type", any>("mode", "type", {
    default: {
      light: css`
        fill: ${theme.colors.grey.grey100};
      `
    },
    success: {
      light: css`
        fill: ${theme.colors.success.success100};
      `
    },
    warning: {
      light: css`
        fill: ${theme.colors.warning.warning100};
      `
    },
    danger: {
      light: css`
        fill: ${theme.colors.alert.alert100};
      `
    },
    defective: {
      light: css`
        fill: ${theme.colors.grey.grey50};
      `
    }
  });

const StyledIcon = styled(Icon)<{ type: string; selected?: boolean }>(
  ({ theme, selected }) => css`
    fill: ${theme.colors.grey.grey50};
    margin-right: ${theme.spaceUnit.xs};

    ${iconType({ theme })};
    ${selected &&
    css`
      fill: ${theme.colors.white};
    `}
  `
);

const chipType = ({
  theme,
  selected
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: DefaultTheme | any;
  selected?: boolean;
}) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theming.variants<"type", any>("mode", "type", {
    default: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.grey.grey100};

        ${selected &&
        css`
          background-color: ${theme.colors.grey.grey50};
          border-color: ${theme.colors.grey.grey50};
        `}
      `
    },
    success: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.success.success100};

        ${selected &&
        css`
          background-color: ${theme.colors.success.success50};
          border-color: ${theme.colors.success.success50};
        `}
      `
    },
    warning: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.warning.warning100};

        ${selected &&
        css`
          background-color: ${theme.colors.warning.warning50};
          border-color: ${theme.colors.warning.warning50};
        `}
      `
    },
    danger: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.alert.alert100};

        ${selected &&
        css`
          background-color: ${theme.colors.alert.alert50};
          border-color: ${theme.colors.alert.alert50};
        `}
      `
    },
    defective: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.grey.grey50};

        ${selected &&
        css`
          background-color: ${theme.colors.grey.grey50};
          border-color: ${theme.colors.alert.grey50};
        `}
      `
    }
  });

const StatusChip = styled(BaseStatusChip).attrs(({ type, selected }) => ({
  CustomBaseChipComponent: Chip,
  renderLeftIcon: () => (
    <StyledIcon type={type} name={iconMap[type]} selected={selected} />
  )
}))<IStatusChipProps<string>>(
  ({ theme, selected }) => css`
    border-radius: 110px;
    height: calc(${theme.spaceUnit.lg} + ${theme.spaceUnit.xs});
    padding: ${theme.spaceUnit.xxs} ${theme.spaceUnit.xs};

    ${chipType({ theme, selected })};
  `
);

StatusChip.displayName = "StatusChip";

export { StatusChipStyles, StyledIcon };

export type { IStatusChipProps };

export default StatusChip;
