import styled, { css, DefaultTheme } from "styled-components";
import theming from "styled-theming";
import type { IStatusChipProps } from "@diana-ui/chip";
import { StatusChip as BaseStatusChip } from "@diana-ui/chip";
import Chip from "./BaseChip";

const chipType = ({
  theme,
  selected
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: DefaultTheme | any;
  selected?: boolean;
}) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theming.variants<"type", any>("mode", "type", {
    default: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.grey.grey100};
        color: ${theme.colors.grey.grey100};

        ${selected &&
        css`
          background-color: ${theme.colors.grey.grey50};
          border-color: ${theme.colors.grey.grey50};
          color: ${theme.colors.black};
        `}
      `
    },
    success: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.success.success100};
        color: ${theme.colors.success.success100};

        ${selected &&
        css`
          background-color: ${theme.colors.success.success50};
          border-color: ${theme.colors.success.success50};
          color: ${theme.colors.black};
        `}
      `
    },
    warning: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.warning.warning100};
        color: ${theme.colors.warning.warning100};

        ${selected &&
        css`
          background-color: ${theme.colors.warning.warning50};
          border-color: ${theme.colors.warning.warning50};
          color: ${theme.colors.black};
        `}
      `
    },
    danger: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.alert.alert100};
        color: ${theme.colors.alert.alert100};

        ${selected &&
        css`
          background-color: ${theme.colors.alert.alert50};
          border-color: ${theme.colors.alert.alert50};
          color: ${theme.colors.black};
        `}
      `
    },
    defective: {
      light: css`
        background-color: transparent;
        border: 1px solid ${theme.colors.grey.grey50};
        color: ${theme.colors.grey.grey50};

        ${selected &&
        css`
          background-color: ${theme.colors.grey.grey50};
          border-color: ${theme.colors.alert.grey50};
          color: ${theme.colors.black};
        `}
      `
    }
  });

const ImpactStatusChip = styled(BaseStatusChip).attrs(() => ({
  CustomBaseChipComponent: Chip
}))<IStatusChipProps<string>>(
  ({ theme, selected }) => css`
    border-radius: 50px;
    height: ${theme.spaceUnit.lg};
    padding: 0 ${theme.spaceUnit.xs};

    ${chipType({ theme, selected })};
  `
);

ImpactStatusChip.displayName = "ImpactStatusChip";

export default ImpactStatusChip;
