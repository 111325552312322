import styled, { css } from "styled-components";
import BaseChip from "./BaseChip";

export const StyledBaseChip = styled(BaseChip)(
  () => css`
    justify-content: space-between;
  `
);

export const DeleteIcon = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: 0 ${theme.spaceUnit.xxs};
    margin-left: ${theme.spaceUnit.xs};
    cursor: pointer;

    :hover {
      stroke: ${theme.colors.black};
    }
  `
);
