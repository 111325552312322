import { Divider as BaseDivider, DividerStyles } from "@diana-ui/divider";
import styled, { css } from "styled-components";

const Divider = styled(BaseDivider)(
  ({ theme }) => css`
    background-color: ${theme.mode === "dark"
      ? theme.colors.grey.darkest
      : theme.colors.grey.grey25};
  `
);

export { DividerStyles };

export default Divider;
