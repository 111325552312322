import React from "react";
import styled, { css } from "styled-components";
import {
  ModalFooter as BaseModalFooter,
  ModalFooterStyles as BaseModalFooterStyles,
  IModalFooterProps
} from "@diana-ui/modal";
import * as ModalFooterStyles from "./ModalFooter.style";

interface IProps extends IModalFooterProps {
  showDivider?: boolean;
}

const StyledBaseModalFooter = styled(BaseModalFooter)(
  ({ theme }) => css`
    padding: ${theme.spaceUnit.lg};
    padding-bottom: ${theme.spaceUnit.md};
  `
);

const ModalFooter: React.FC<IProps> = ({
  showDivider = true,
  ref,
  ...props
}) => (
  <>
    {showDivider && (
      <ModalFooterStyles.DividerWrapper>
        <ModalFooterStyles.StyledDivider />
      </ModalFooterStyles.DividerWrapper>
    )}
    <StyledBaseModalFooter {...props} />
  </>
);

export { ModalFooterStyles, BaseModalFooterStyles };

export default ModalFooter;
