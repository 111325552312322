/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { DataTableProps, DataTableStyles } from "@diana-ui/data-table";
import { Icon } from "@ddm-design-system/icon";
import {
  StyledAvatar,
  StyledContainerImageAvatar,
  StyledDataTable,
  StyledIcon,
  StyledImageAvatar,
  StyledImpactStatusChip
} from "./DataTable.style";
import DataTablePagination, { DataTablePaginationProps } from "./Pagination";

const DataTable = <T,>({
  columns = [],
  customStyles,
  data = [],
  theme,
  ...props
}: DataTableProps<T>) => {
  const ddmTheme = useTheme();
  const [changedColumns, setChangedColumns] = useState<typeof columns>([]);

  const defaultSortIcon = <StyledIcon name="ChevronDown" size={6} />;

  const ddmCustomStyles = useMemo(() => {
    return {
      headCells: {
        style: {
          // @ts-ignore
          ...ddmTheme.typography.description,
          // @ts-ignore
          color: ddmTheme.colors.grey.grey100
        },
        activeSortStyle: {
          // @ts-ignore
          ...ddmTheme.typography.descriptionMedium,
          // @ts-ignore
          color: ddmTheme.colors.black
        }
      },
      cells: {
        style: {
          // @ts-ignore
          ...ddmTheme.typography.description,
          // @ts-ignore
          color: ddmTheme.colors.grey.greyDarker
        },
        activeSortStyle: {
          // @ts-ignore
          ...ddmTheme.typography.descriptionMedium,
          // @ts-ignore
          color: ddmTheme.colors.black
        }
      },
      ...customStyles
    };
  }, [customStyles, ddmTheme]);

  const handleCellFormat = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    row: any,
    rowIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    column: any
  ) => {
    const columnSelector = column.selector;
    const urlsLimit = 2;
    let groupOfImages = null;

    // detects if the column is an image selector
    if (column.isImageSelector) {
      // here it will filter the first 2 images to present, and map then as an image avatar
      groupOfImages = columnSelector(row)
        .filter(
          (
            photo: string | { photo: string; title: string },
            photoIndex: number
          ) => photoIndex < urlsLimit
        )
        .map(
          (photo: string | { photo: string; title: string }, index: number) => {
            if (typeof photo === "string") {
              return <StyledImageAvatar img={{ src: photo }} key={index} />;
            }
            return (
              <StyledContainerImageAvatar key={index} title={photo.title}>
                <StyledImageAvatar img={{ src: photo.photo }} />
              </StyledContainerImageAvatar>
            );
          }
        );

      if (columnSelector(row).length > urlsLimit) {
        const numberOfExtraImages = columnSelector(row).length - urlsLimit;

        groupOfImages = [
          ...groupOfImages,
          <StyledAvatar key="extra">{`+${numberOfExtraImages}`}</StyledAvatar>
        ];
      }

      return groupOfImages;
    }

    // detects if the cell has a link (using the textLinkSelectors array)
    if (row.textLinkSelectors) {
      const cellWithLink = row.textLinkSelectors.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (selector: any) => selector.name === column.selectorId
      );

      if (cellWithLink) {
        return (
          // eslint-disable-next-line
          <a className="rdt_TableRow_Link" onClick={cellWithLink.handleOnClick}>
            {row[cellWithLink.name]}
          </a>
        );
      }
    }

    // detects if the cell has a chip (using the chipSelectors array)
    if (row.chipSelectors) {
      const cellWithChips = row.chipSelectors.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (selector: any) => selector.name === column.selectorId
      );

      if (cellWithChips)
        return (
          <StyledImpactStatusChip type={cellWithChips.type}>
            {row[cellWithChips.name]}
          </StyledImpactStatusChip>
        );
    }

    return <div>{columnSelector(row)}</div>;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateHandleFormatFunction = (column: any) => {
    if (column.cell) return column.cell;

    return handleCellFormat;
  };

  useEffect(() => {
    const newColumns = columns.map((column: any) => {
      if (column.format) return column;

      return {
        ...column,
        cell: validateHandleFormatFunction(column)
      };
    });

    setChangedColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  return (
    <StyledDataTable
      data={data}
      columns={changedColumns}
      sortIcon={defaultSortIcon || props.sortIcon}
      customStyles={ddmCustomStyles}
      paginationPerPage={8}
      paginationComponent={(propsPagination: DataTablePaginationProps) => (
        <DataTablePagination {...propsPagination} />
      )}
      expandableIcon={{
        collapsed: <Icon name="ChevronDown" />,
        expanded: <Icon name="ChevronUp" />
      }}
      expandOnRowClicked
      {...props}
    />
  );
};

export { DataTableStyles };

export type { DataTableProps };

export default DataTable;
