import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";
import {
  Avatar,
  ImageAvatar,
  ImageAvatarStyles
} from "@ddm-design-system/avatar";
import { DataTable as BasicDataTable } from "@diana-ui/data-table";
import { BaseChipStyles, ImpactStatusChip } from "@ddm-design-system/chip";

export const StyledDataTable = styled(BasicDataTable)(
  ({ theme }) => css`
    .rdt_TableRow:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.grey.greenish};
    }

    .rdt_TableCell {
      align-items: unset;
    }
  `
);

export const StyledImpactStatusChip = styled(ImpactStatusChip)(
  ({ theme }) => css`
    ${BaseChipStyles.Text} {
      font-size: ${theme.typography.description.fontSize};
    }
  `
);

export const StyledIcon = styled(Icon)<{
  size: number;
}>(
  ({ theme, size }) => css`
    &&& {
      vertical-align: middle;
      margin-right: ${theme.spaceUnit.xxs};
      margin-left: ${theme.spaceUnit.xxs};
      width: ${size}px !important;
    }
  `
);

export const StyledContainerImageAvatar = styled.div(
  ({ theme }) => css`
    &:not(:first-child) {
      margin-left: ${theme.spaceUnit.xs};
    }
  `
);

export const StyledImageAvatar = styled(ImageAvatar)(
  ({ theme }) => css`
    position: static;
    width: ${theme.spaceUnit.xl};
    height: ${theme.spaceUnit.xl};
    left: ${theme.spaceUnit.md};
    top: ${theme.spaceUnit.xxs};
    overflow: hidden;
    border-width: 1px;

    &:not(:first-child) {
      margin-left: ${theme.spaceUnit.xs};
    }

    ${ImageAvatarStyles.Image} {
      border-radius: ${theme.spaceUnit.xxs};
    }
  `
);

export const StyledAvatar = styled(Avatar)(
  ({ theme }) => css`
    border-color: ${theme.colors.grey.grey50};
    width: ${theme.spaceUnit.xl};
    height: ${theme.spaceUnit.xl};
    left: ${theme.spaceUnit.md};
    top: ${theme.spaceUnit.xxs};
    margin-left: ${theme.spaceUnit.xs};
    border-radius: ${theme.spaceUnit.xxs};
    position: static;
  `
);
