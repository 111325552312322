import styled, { css } from "styled-components";
import { BodyHighlight } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";

export const StyledBodyHighlight = styled(BodyHighlight)``;

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    fill: ${theme.colors.grey.grey100};
    margin-right: ${theme.spaceUnit.md};
    height: ${theme.typography.buttonText.fontSize};
    width: ${theme.typography.buttonText.fontSize};
  `
);
