import styled, { css } from "styled-components";
import { Menu as BaseMenu, MenuStyles as BaseMenuStyles } from "@diana-ui/menu";

export { BaseMenuStyles };

export default styled(BaseMenu)(
  ({ theme }) => css`
    ${BaseMenuStyles.List} {
      border: 1px solid
        ${theme.mode === "dark"
          ? theme.colors.black
          : theme.colors.grey.greenish};
      border-radius: 10px;
    }
  `
);
