import { BaseChip as BaseBaseChip, BaseChipStyles } from "@diana-ui/chip";
import type { IBaseChipProps } from "@diana-ui/chip";
import styled, { css } from "styled-components";

const BaseChip = styled(BaseBaseChip)(
  ({ theme }) => css`
    border-radius: 4px;
    background-color: ${theme.colors.grey.grey25};
    border: 0;
    padding: 1px ${theme.spaceUnit.sm};

    ${BaseChipStyles.Text} {
      font-family: ${theme.fontFamily};
    }
  `
);

BaseChip.displayName = "BaseChip";

export { BaseChipStyles };

export type { IBaseChipProps };

export default BaseChip;
