import styled, { css } from "styled-components";
import { NavButton, NavButtonMobile } from "@ddm-design-system/nav-button";
import { Icon } from "@ddm-design-system/icon";

export const Wrapper = styled.nav<{ isMobile: boolean; isTablet: boolean }>(
  ({ theme, isMobile, isTablet }) => css`
    display: flex;

    ${isMobile &&
    css`
      position: fixed;
      bottom: 0px;
      height: 54px;
      width: 100%;
      z-index: 2;
      background-color: ${theme.colors.white};
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid ${theme.colors.grey.grey25};
    `}

    ${isTablet &&
    css`
      max-width: ${theme.spaceUnit.xxxl};
      display: flex;
      align-items: center;
      flex-direction: column;
    `};

    ${!isMobile &&
    !isTablet &&
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 200px;
      > div,
      > a {
        width: 100%;
      }
    `};
  `
);

export const HeaderWrapperLogo = styled.div<{ isTablet: boolean }>(
  ({ theme, isTablet }) => css`
    display: flex;
    margin-top: ${theme.spaceUnit.xxl};
    margin-bottom: ${theme.spaceUnit.xxl};

    ${isTablet &&
    css`
      margin-top: ${theme.spaceUnit.lg};
      margin-bottom: ${theme.spaceUnit.lg};
    `}
  `
);

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    fill: ${theme.colors.black};
  `
);

export const StyledNavButton = styled(NavButton)``;

export const StyledNavButtonMobile = styled(NavButtonMobile)``;
