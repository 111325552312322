import React, { useState } from "react";
import { Icon } from "@ddm-design-system/icon";
import ErrorTextInput, { IErrorTextInputProps } from "./ErrorTextInput";

const PasswordInput: React.FC<IErrorTextInputProps> = props => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <ErrorTextInput
      ref={props.ref as any}
      {...props}
      type={showPassword ? "text" : "password"}
      suffixIcon={
        <Icon
          onClick={() => setShowPassword(!showPassword)}
          style={{ cursor: "pointer" }}
          name={!showPassword ? "Eye" : "EyeClosed"}
        />
      }
    />
  );
};

export default PasswordInput;
