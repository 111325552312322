import styled from "styled-components";
import { IIconProps } from "@ddm-design-system/icon";
import {
  NotificationStack as BaseNotificationStack,
  INotification,
  INotificationStackRef
} from "@diana-ui/notification";
import Notification from "./Notification";

export interface ICustomNotification extends INotification {
  iconProps?: IIconProps;
}

export interface ICustomNotificationStackRef extends INotificationStackRef {
  addNotification: (notification: ICustomNotification) => string;
}

export default styled(BaseNotificationStack).attrs({
  CustomNotificationComponent: Notification
})``;
