import React, { forwardRef, ReactElement } from "react";
import styled, { css } from "styled-components";
import {
  SimpleMultipleDropdown as BaseSimpleMultipleDropdown,
  ISimpleMultipleDropdownProps,
  BaseMultipleDropdownStyles,
  SimpleMultipleDropdownStyles as BaseSimpleMultipleDropdownStyles,
  IItem,
  PopoverStyles
} from "@diana-ui/dropdown";
import type { IPopoverRef } from "@diana-ui/dropdown";
import { FontWeight } from "@ddm-design-system/tokens";
import { DropdownStyles, ICON_SIZE_PX } from "./Dropdown";
import * as SimpleMultipleDropdownStyles from "./SimpleMultipleDropdown.style";

const StyledSimpleDropdown = styled(BaseSimpleMultipleDropdown)(
  ({ theme, disabled }) => css`
    ${disabled &&
    css`
      ${DropdownStyles.TextWrapper} {
        color: ${theme.colors.grey.grey50};
      }

      ${DropdownStyles.StyledIcon} {
        stroke: ${theme.colors.grey.grey50};
      }

      ${BaseMultipleDropdownStyles.StyledLabel} {
        color: ${theme.colors.grey.grey50};
      }

      ${BaseMultipleDropdownStyles.StyledPopover} {
        ${BaseMultipleDropdownStyles.StyledLi} {
          color: ${theme.colors.grey.grey50};
        }
      }
    `}

    ${BaseMultipleDropdownStyles.StyledPopover} {
      background-color: ${theme.colors.white};
      border-radius: 4px 4px 0 0;
    }

    ${BaseMultipleDropdownStyles.StyledLabel} {
      font-size: 18px;
      font-family: ${theme.fontFamily};
      margin-right: ${theme.spaceUnit.xs};

      ${disabled &&
      css`
        color: ${theme.colors.grey.grey50};
      `}
    }

    ${BaseMultipleDropdownStyles.StyledUl} {
      border: 1px solid ${theme.colors.black};
      border-radius: 0 0 4px 4px;
      border-top: 1px solid ${theme.colors.grey.grey25};
      list-style: none;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
      overflow-y: auto;
      text-align: start;
      width: 100%;
    }

    ${BaseMultipleDropdownStyles.StyledDropdownheader} {
      min-width: 220px;
    }

    ${BaseMultipleDropdownStyles.StyledAllLi},
    ${BaseMultipleDropdownStyles.StyledLi} {
      background-color: ${theme.colors.white};
      cursor: pointer;
      padding: ${theme.spaceUnit.sm};
      height: 24px;
      display: flex;
      align-items: center;

      :hover {
        background-color: ${theme.colors.grey.grey25};
      }
      :last-child {
        border-radius: 0 0 10px 10px;
      }

      &.selected {
        font-weight: ${FontWeight.BOLD};
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      > * {
        width: 100%;
      }
    }

    ${BaseMultipleDropdownStyles.StyledItemText} {
      display: block;
      font-size: 18px;
      font-family: ${theme.fontFamily};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${PopoverStyles.Container} {
      &.top {
        padding-top: 0;
        margin-top: 1px;
      }
    }

    ${PopoverStyles.HeaderWrapper} {
      &.top {
        ${DropdownStyles.TextWrapper} {
          &.open {
            border-top: 0;
            border-bottom: 1px solid;
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }

    ${PopoverStyles.StyledPopover} {
      &.top {
        ${BaseMultipleDropdownStyles.StyledUl} {
          border-radius: 4px 4px 0 0;
          border-top: 1px solid ${theme.colors.black};
          border-bottom: 1px solid ${theme.colors.grey.grey25};

          ${BaseMultipleDropdownStyles.StyledLi} {
            :last-child {
              border-radius: 0;
            }

            :first-child {
              border-radius: 4px 4px 0 0;
            }
          }
        }
      }
    }
  `
);

const SimpleMultipleDropdown = forwardRef<
  IPopoverRef,
  ISimpleMultipleDropdownProps<IItem>
>(
  (
    {
      disabled,
      items,
      placeholder = "\u00A0", // non blocking space to force header line height
      selectAllText,
      selectedItems,
      text,
      ...props
    },
    ref
  ) => {
    const isDisabled = disabled || items.length === 0;

    const renderHeader = (isOpen: boolean): ReactElement => (
      <DropdownStyles.TextWrapper
        className={[isOpen && "open", isDisabled && "disabled"]
          .filter(Boolean)
          .join(" ")}
      >
        <DropdownStyles.Text>
          {text ??
            (selectedItems.length
              ? selectedItems
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .map((i: any) => i.text)
                  .sort()
                  .join(", ")
              : placeholder)}
        </DropdownStyles.Text>
        <DropdownStyles.StyledIcon
          className={[isOpen && "open", isDisabled && "disabled"]
            .filter(Boolean)
            .join(" ")}
          name="ChevronDown"
          size={ICON_SIZE_PX}
        />
      </DropdownStyles.TextWrapper>
    );

    const selectAllItem = (checked: boolean): ReactElement => (
      <SimpleMultipleDropdownStyles.StyledCheckbox
        checked={checked}
        disabled={disabled}
      >
        {selectAllText ?? "Select all"}
      </SimpleMultipleDropdownStyles.StyledCheckbox>
    );

    const renderItem = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item: any,
      isSelected: boolean,
      isAllSelected: boolean
    ): ReactElement => {
      return (
        <SimpleMultipleDropdownStyles.StyledCheckbox
          checked={!isAllSelected && isSelected}
          showAsChecked={isAllSelected && !item.disabled}
          disabled={disabled}
        >
          <SimpleMultipleDropdownStyles.StyledItemText>
            {item.text}
          </SimpleMultipleDropdownStyles.StyledItemText>
        </SimpleMultipleDropdownStyles.StyledCheckbox>
      );
    };

    return (
      <StyledSimpleDropdown
        disabled={isDisabled}
        items={items}
        renderHeader={renderHeader}
        renderItem={renderItem}
        selectAllItem={selectAllItem}
        selectedItems={selectedItems}
        {...(props as any)}
        ref={ref}
      />
    );
  }
);

export { BaseSimpleMultipleDropdownStyles };

export default SimpleMultipleDropdown;
