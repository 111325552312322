import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";
// eslint-disable-next-line import/no-cycle
import { ICON_SIZE_PX } from "./Dropdown";

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    transition: 0.2s transform;
    transform: rotateZ(0deg);

    &.open {
      transform: rotateZ(-180deg);
    }

    &.disabled {
      stroke: ${theme.colors.grey.grey50};
    }
  `
);

export const Text = styled.span(
  ({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.fontFamily};
    font-weight: 500;
    font-style: normal;
    max-width: calc(
      220px - 2 * ${theme.spaceUnit.sm} - ${ICON_SIZE_PX}px - 4px
    );
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
);

export const TextWrapper = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.grey.grey50};
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spaceUnit.sm};

    :hover {
      border-color: ${theme.colors.black};
    }

    &.open {
      border-color: ${theme.colors.black};
      border-bottom: 0;
      border-radius: 4px 4px 0 0;
    }

    &.disabled {
      color: ${theme.colors.grey.grey50};
    }
  `
);
