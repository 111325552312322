import React, { ChangeEvent, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";
import { ITextInputProps } from "@diana-ui/textinput";
import TextInput, { TextInputStyles } from "./TextInput";

export const ClearIcon = styled(Icon).attrs({ name: "Close", size: 10 })<{
  $isHidden?: boolean;
}>(
  ({ $isHidden, theme }) => css`
    cursor: pointer;
    margin-right: ${theme.spaceUnit.xs};

    ${$isHidden &&
    css`
      visibility: hidden;
    `}
  `
);

const StyledSearchInput = styled(TextInput).attrs(props => ({
  type: "search" as string,
  prefixIcon: props.prefixIcon || "search"
}))(
  ({ theme, prefixIcon }) => css`
    border-radius: 110px;

    ${TextInputStyles.InputContainer} {
      padding-left: ${theme.spaceUnit.md};
      padding-right: ${theme.spaceUnit.xs};
    }

    &.active,
    &.focus {
      ${TextInputStyles.TextLabel} {
        padding-left: ${theme.spaceUnit.md};
      }
    }

    ${TextInputStyles.LabelContainer} {
      left: ${theme.spaceUnit.xs};
    }

    ${prefixIcon &&
    css`
      ${TextInputStyles.TextLabel} {
        padding-left: ${theme.spaceUnit.xxl};
      }
    `}
    ${TextInputStyles.Legend} {
      margin-left: ${theme.spaceUnit.sm};
    }

    ${TextInputStyles.PrefixIcon} {
      overflow: visible;
      stroke: ${theme.colors.grey.grey100};
    }

    ${TextInputStyles.Input} {
      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    ${TextInputStyles.SuffixWrapper} {
      display: flex;
      margin: 0;
    }
  `
);

const SearchInput: React.FC<ITextInputProps> = ({
  onChange,
  value = "",
  ...props
}) => {
  const [searchValue, setSearchValue] = useState<string>(value as string);

  const handleChange = useCallback(
    e => {
      setSearchValue(e.target.value);
      onChange?.(e);
    },
    [setSearchValue, onChange]
  );

  const handleFocusCursor = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      if (event.currentTarget !== document.activeElement) {
        const position = searchValue.length;

        event.preventDefault();
        event.currentTarget.focus();
        event.currentTarget.setSelectionRange(position, position);
      }
    },
    [searchValue]
  );

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      event.preventDefault();
      setSearchValue("");
      onChange?.({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
    },
    [setSearchValue, onChange]
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledSearchInput
      onChange={handleChange}
      onMouseDown={handleFocusCursor}
      suffixIcon={<ClearIcon $isHidden={!searchValue} onClick={handleClear} />}
      value={searchValue as string}
      {...props}
    />
  );
};

export default SearchInput;
