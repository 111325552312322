import styled from "styled-components";
import {
  CloseableChip as BaseCloseableChip,
  CloseableChipStyles,
  ICloseableChipProps
} from "@diana-ui/chip";
import Chip from "./BaseChip";

const CloseableChip = styled(BaseCloseableChip).attrs<ICloseableChipProps>({
  CustomBaseChipComponent: Chip
})``;

CloseableChip.displayName = "CloseableChip";

export type { ICloseableChipProps };

export { CloseableChipStyles };

export default CloseableChip;
