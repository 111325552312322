import React, { ForwardRefExoticComponent } from "react";
import styled, { css, DefaultTheme, StyledComponent } from "styled-components";
import {
  Checkbox as BaseCheckbox,
  CheckboxStyles as BaseCheckboxStyles
} from "@diana-ui/checkbox";
import type { ICheckboxProps } from "@diana-ui/checkbox";
import { Icon } from "@ddm-design-system/icon";

export const StyledCheckedIcon = styled(Icon)``;

const Checkbox: StyledComponent<
  ForwardRefExoticComponent<ICheckboxProps>,
  DefaultTheme
> = styled(BaseCheckbox).attrs(() => ({
  checkedIcon: <StyledCheckedIcon name="Check" />
}))<ICheckboxProps>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    outline-style: none;
    user-select: none;
    cursor: pointer;

    .disabled {
      cursor: initial;

      .custom-icon {
        stroke: ${theme.colors.grey.grey100};
      }
      ${BaseCheckboxStyles.IconContainer} {
        background-color: ${theme.colors.grey.grey25};
        border-color: ${theme.colors.grey.grey100};
      }
    }

    &.show-as-checked {
      .custom-icon {
        opacity: 1;
        stroke: ${theme.colors.black};
      }
    }

    &.checked {
      .custom-icon {
        opacity: 1;
        stroke: ${theme.colors.white};
      }

      ${BaseCheckboxStyles.IconContainer} {
        background-color: ${theme.colors.black};
      }
    }

    :hover:not(.disabled):not(.checked):not(.show-as-checked) {
      .custom-icon {
        opacity: 1;
        stroke: ${theme.colors.grey.grey100};
      }
    }

    ${BaseCheckboxStyles.CheckboxText} {
      cursor: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${theme.typography.body};
    }

    .custom-icon {
      opacity: 0;
      stroke: ${theme.colors.black};
      transition: 0.3s stroke, 0.3s opacity;
    }

    ${BaseCheckboxStyles.IconContainer} {
      width: ${theme.spaceUnit.md};
      height: ${theme.spaceUnit.md};
      border: 1px solid;
      border-color: ${theme.colors.black};
      box-sizing: border-box;
      border-radius: 2px;
      position: relative;
      background-color: ${theme.colors.white};
      transition: 0.3s background-color, 0.3s border-color;
      margin-right: ${theme.spaceUnit.xs};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
  `
);

export { BaseCheckboxStyles };

export type { ICheckboxProps };

export default Checkbox;
