import styled, { css } from "styled-components";
import {
  RadioGroup as BaseRadioGroup,
  RadioGroupStyles
} from "@diana-ui/radio";

export { RadioGroupStyles };

export default styled(BaseRadioGroup)(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `
);
