import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";

export const StyledPagination = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: ${theme.spaceUnit.md};
    overflow: hidden;
  `
);

export const StyledPaginationIconLeft = styled(Icon)<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    margin-right: ${theme.spaceUnit.md};
    cursor: pointer;
    ${disabled &&
    css`
      pointer-events: none;
      fill: ${theme.colors.grey.grey50};
    `}
  `
);

export const StyledPaginationIconRight = styled(Icon)<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    margin-left: ${theme.spaceUnit.md};
    cursor: pointer;
    ${disabled &&
    css`
      pointer-events: none;
      fill: ${theme.colors.grey.grey50};
    `}
  `
);
