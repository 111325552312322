import React, { useCallback } from "react";
import { INavButtonProps } from "@ddm-design-system/nav-button";
import { useIsMobile, useIsTablet } from "@ddm-design-system/hooks";
import * as NavbarStyles from "./Navbar.style";

export interface INavbarItem extends INavButtonProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linkOptions: any;
}

export interface INavbarProps {
  items: INavbarItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LinkComponent: any;
  forceVersion?: "mobile" | "tablet" | "desktop";
}

const Navbar: React.FC<INavbarProps> = ({
  LinkComponent,
  items,
  forceVersion
}) => {
  let isMobile = useIsMobile();
  let isTablet = useIsTablet();

  if (forceVersion) {
    isMobile = forceVersion === "mobile";
    isTablet = forceVersion === "tablet";
  }

  const ButtonComponent = isMobile
    ? NavbarStyles.StyledNavButtonMobile
    : NavbarStyles.StyledNavButton;

  const handleClick = useCallback((e: Event, disabled: boolean) => {
    if (disabled) {
      e.preventDefault();
    }
  }, []);

  return (
    <NavbarStyles.Wrapper isMobile={isMobile} isTablet={isTablet}>
      {!isMobile && (
        <NavbarStyles.HeaderWrapperLogo isTablet={isTablet}>
          <NavbarStyles.StyledIcon
            name={isTablet ? "DmLogo" : "DmFullLogo"}
            height={32}
          />
        </NavbarStyles.HeaderWrapperLogo>
      )}
      {items.map(({ linkOptions, title, ...buttonProps }, ind) => (
        <LinkComponent
          {...linkOptions}
          // eslint-disable-next-line react/no-array-index-key
          key={ind}
          onClick={(e: Event) => handleClick(e, !!buttonProps.disabled)}
        >
          <ButtonComponent {...(isMobile ? {} : { isTablet })} {...buttonProps}>
            {title}
          </ButtonComponent>
        </LinkComponent>
      ))}
    </NavbarStyles.Wrapper>
  );
};

Navbar.displayName = "Navbar";

export { NavbarStyles };

export default Navbar;
