import styled, { css } from "styled-components";
import { Icon } from "@diana-ui/icon";
import { Body, BodyHighlight } from "@diana-ui/typography";

/**
 * Component text
 */
export const StyledText = styled(Body)``;

export const TextIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.sm};
  `
);

export const StyledTitle = styled(BodyHighlight)``;

export const TitleIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.sm};
  `
);

export const Wrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;

    &.enter {
      opacity: 0;
      transform: translateY(10px);
    }

    &.enter-active {
      opacity: 1;
      transition: opacity 250ms ease-in, transform 125ms ease-in;
      transform: translateY(10px);
    }

    &.exit {
      opacity: 1;
      transform: translateY(10px);
    }

    &.exit-active {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 250ms ease-in, transform 125ms ease-in;
    }
  `
);

export const ContentWrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `
);
