/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { DefaultTheme, useTheme } from "styled-components";

import { useWindowSize } from "./useWindowSize";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCheckWindowSize = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  highBreakpoint: number | keyof DefaultTheme["breakpoints"] = "mobile-1",
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  lowBreakpoint: number | keyof DefaultTheme["breakpoints"] = 0
) => {
  const [width] = useWindowSize();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme = useTheme() as any;

  let breakpointToCheck = highBreakpoint;
  let lowBreakpointToCheck = lowBreakpoint;

  if (typeof highBreakpoint === "string") {
    breakpointToCheck = theme.breakpoints[highBreakpoint.toLowerCase()] || 0;
  }

  if (typeof lowBreakpoint === "string") {
    lowBreakpointToCheck = theme.breakpoints[lowBreakpoint.toLowerCase()] || 0;
  }

  return (breakpointToCheck as number) > width && width > (lowBreakpointToCheck as number);
};

export default useCheckWindowSize;
