import styled, { css } from "styled-components";
import { Icon } from "@ddm-design-system/icon";
import { DescriptionMedium } from "@ddm-design-system/typography";
import { PopoverStyles } from "@diana-ui/popover";
import Dropdown, { BaseDropdownStyles } from "./Dropdown";

export const StyledDropdown = styled(Dropdown)(
  ({ theme }) => css`
    ${BaseDropdownStyles.StyledPopover} {
      min-width: auto;
      width: 100%;
      max-width: 220px;
    }

    ${BaseDropdownStyles.List} {
      border-color: ${theme.colors.grey.greenish};
      border-radius: 4px;
      border-top-width: 1px;
      margin-top: ${theme.spaceUnit.xxs};
    }

    ${BaseDropdownStyles.ItemText} {
      ${theme.typography.description}
    }

    ${PopoverStyles.Container} {
      &.top {
        padding-top: 0;
        padding-bottom: ${theme.spaceUnit.md};
        margin-top: 1px;
      }
    }

    ${PopoverStyles.StyledPopover} {
      &.top {
        ${BaseDropdownStyles.List} {
          border-radius: 4px 4px 4px 4px;
          border-top: 1px solid ${theme.colors.grey.grey25};
          border-bottom: 1px solid ${theme.colors.grey.grey25};
          margin-top: 0;
          margin-bottom: ${theme.spaceUnit.xs};

          ${BaseDropdownStyles.Item} {
            :last-child {
              border-radius: 0;
            }
            :first-child {
              border-radius: 4px 4px 0 0;
            }
          }
        }
      }
    }
  `
);

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    transition: 0.2s transform;
    transform: rotateZ(0deg);

    &.open {
      transform: rotateZ(-180deg);
    }

    &.disabled {
      stroke: ${theme.colors.grey.grey50};
    }
  `
);

export const Text = styled(DescriptionMedium)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xs};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.2rem !important;
  `
);

export const TextWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${theme.spaceUnit.xs};

    :hover {
      border-color: ${theme.colors.black};
    }

    &.open {
      border-color: ${theme.colors.black};
      border-bottom: 0;
      border-radius: 4px 4px 0 0;
    }

    &.disabled {
      color: ${theme.colors.grey.grey50};
    }
  `
);
