import styled, { css } from "styled-components";
import { ErrorTextInput, ErrorTextInputStyles, TextInputStyles } from "@diana-ui/textinput";
import ChipList, { ChipListStyles } from "./ChipList";
import { IChipInputProps } from "./ChipInput.types";

export const ChipInputWrapper = styled.div<IChipInputProps>`
  display: flex;
  width: 100%;

  ${ErrorTextInputStyles.ErrorTextInputWrapper} {
    max-width: 100%;
  }
`;

export const StyledErrorTextInput = styled(ErrorTextInput)<{
  singleChip: boolean;
  chips: string[];
}>(
  ({ theme, chips, singleChip }) => css`
    ${TextInputStyles.TextLabel} {
      padding-left: ${theme.spaceUnit.xxs};
    }

    ${TextInputStyles.Input} {
      min-width: 100px;

      ${!!chips?.length &&
      singleChip &&
      css`
        display: none;
      `}
    }

    ${TextInputStyles.PrefixWrapper} {
      overflow-x: auto;
      ${singleChip &&
      css`
        width: 100%;
        margin-right: 0;
      `}
    }

    ${TextInputStyles.InputContainer} {
      overflow-x: auto;
    }

    ${singleChip &&
    css`
      width: 100%;
      margin-right: 0;
    `}
  `
);

export const StyledChipList = styled(ChipList)<{
  singleChip: boolean;
}>`
  ${({ singleChip }) =>
    singleChip &&
    css`
      width: 100%;
      margin-right: 0;

      ${ChipListStyles.ChipContainer} {
        width: 100%;
      }
    `}
`;
