import styled, { css } from "styled-components";
import {
  MenuItem as BaseMenuItem,
  MenuItemStyles as BaseMenuItemStyles
} from "@diana-ui/menu";

export { BaseMenuItemStyles };

export default styled(BaseMenuItem)(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    box-sizing: border-box;
    padding: ${theme.spaceUnit.md};

    :hover {
      background-color: ${theme.colors.grey.grey25};
    }
  `
);
