import React, { useMemo } from "react";
import { DescriptionMedium } from "@ddm-design-system/typography";
import * as PaginationStyles from "./Pagination.style";

interface DataTablePaginationProps {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: (page: number) => void;
  currentPage: number;
}

const DataTablePagination: React.FC<DataTablePaginationProps> = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage
}) => {
  const lastPage = useMemo(() => {
    return Math.ceil(rowCount / rowsPerPage);
  }, [rowCount, rowsPerPage]);

  return (
    <PaginationStyles.StyledPagination>
      <PaginationStyles.StyledPaginationIconLeft
        disabled={currentPage === 1}
        onClick={() => onChangePage(1)}
        name="StepBackward"
      />
      <PaginationStyles.StyledPaginationIconLeft
        disabled={currentPage === 1}
        onClick={() => onChangePage(currentPage - 1)}
        name="ChevronLeft"
      />
      <DescriptionMedium>
        {currentPage}/{lastPage}
      </DescriptionMedium>
      <PaginationStyles.StyledPaginationIconRight
        disabled={currentPage === lastPage}
        onClick={() => onChangePage(currentPage + 1)}
        name="ChevronRight"
      />
      <PaginationStyles.StyledPaginationIconRight
        disabled={currentPage === lastPage}
        onClick={() => onChangePage(lastPage)}
        name="StepForward"
      />
    </PaginationStyles.StyledPagination>
  );
};

export { PaginationStyles as DataTablePaginationStyles };

export type { DataTablePaginationProps };

export default DataTablePagination;
