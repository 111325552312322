import * as Icons from "../icons";
import { ddmPalette } from "../palettes";
import * as animations from "../animations";
import baseTheme from "./theme.ddm.base";

export const spaceUnit = 16;

const theme = {
  ...baseTheme,
  mode: "light",
  name: "ddm",
  colors: ddmPalette,
  animations,
  icons: Icons
};

export default theme;
