import styled, { css } from "styled-components";
import theming from "styled-theming";
import BaseChip from "./BaseChip";
import type { IBaseChipProps } from "./BaseChip.types";

const statusChipVariations = ({ selected }: IBaseChipProps) =>
  theming.variants("mode", "type", {
    default: {
      light: css`
        ${({ theme }) => css`
          color: ${theme.colors.grey.grey100};
          border-color: ${theme.colors.grey.grey100};

          ${selected &&
          css`
            color: ${theme.colors.black};
            background-color: ${theme.colors.grey.grey25};
            border-color: ${theme.colors.grey.grey25};
          `}
        `}
      `
    },
    success: {
      light: css`
        ${({ theme }) => css`
          color: ${theme.colors.success.success100};
          border-color: ${theme.colors.success.success100};

          ${selected &&
          css`
            color: ${theme.colors.black};
            background-color: ${theme.colors.success.success25};
            border-color: ${theme.colors.success.success25};
          `}
        `}
      `
    },
    warning: {
      light: css`
        ${({ theme }) => css`
          color: ${theme.colors.warning.warning100};
          border-color: ${theme.colors.warning.warning100};

          ${selected &&
          css`
            color: ${theme.colors.black};
            background-color: ${theme.colors.warning.warning25};
            border-color: ${theme.colors.warning.warning25};
          `}
        `}
      `
    },
    danger: {
      light: css`
        ${({ theme }) => css`
          color: ${theme.colors.alert.alert100};
          border-color: ${theme.colors.alert.alert100};

          ${selected &&
          css`
            color: ${theme.colors.black};
            background-color: ${theme.colors.alert.alert25};
            border-color: ${theme.colors.alert.alert25};
          `}
        `}
      `
    }
  });

export const StyledStatusChipRounded = styled(BaseChip)(
  props => css`
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid;
    border-radius: 50px;
    padding: 4px 8px;

    ${statusChipVariations(props)}
  `
);
