import styled from "styled-components";
import {
  ChipList as BaseChipList,
  ChipListStyles,
  IChipListProps
} from "@diana-ui/chip";
import CloseableChip from "./CloseableChip";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ChipList = styled(BaseChipList).attrs<IChipListProps<any>>({
  CustomCloseableChipComponent: CloseableChip
})``;

ChipList.displayName = "ChipList";

export { ChipListStyles };
export type { IChipListProps };

export default ChipList;
