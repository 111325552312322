import { ForwardRefRenderFunction } from "react";
import styled, { css } from "styled-components";
import { Popover, IPopoverProps, IPopoverRef } from "@diana-ui/popover";
import { DropdownHeader } from "./Dropdown";

/**
 * Component
 */
export const BaseMultipleDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.div`
  padding: 10px 0;
`;

export const StyledUl = styled.ul`
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
`;

export const StyledAllLi = styled.li``;

export const StyledLi = styled.li`
  cursor: pointer;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
`;

export const StyledItemText = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledPopover = styled(Popover)<ForwardRefRenderFunction<IPopoverRef, IPopoverProps>>(
  () => css`
    max-width: 100%;
    min-width: 220px;
  `
);

export const StyledDropdownheader = styled(DropdownHeader)``;
