import React from "react";
import styled, { css } from "styled-components";
import { IconNames } from "@ddm-design-system/icon";
import { BaseButton, IBaseButtonProps } from "@diana-ui/button";
import * as NavButtonStyles from "./NavButton.style";

export interface IProps {
  isTablet?: boolean;
  disabled?: boolean;
  icon: IconNames;
  selected?: boolean;
}

export type INavButtonProps = IProps & IBaseButtonProps;

export default styled(BaseButton).attrs<INavButtonProps>(
  ({ isTablet, children, icon }) => ({
    children: isTablet ? undefined : (
      <NavButtonStyles.StyledBodyHighlight>
        {children as any}
      </NavButtonStyles.StyledBodyHighlight>
    ),
    renderLeftIcon: () => <NavButtonStyles.StyledIcon name={icon} />
  })
)<INavButtonProps>(
  ({ theme, isTablet, selected, disabled }) => css`
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 10px;
    color: ${theme.colors.grey.grey100};
    justify-content: flex-start;
    width: 100%;
    min-width: ${isTablet ? "unset" : "176px"};
    padding: ${theme.spaceUnit.md};
    box-sizing: border-box;

    &:hover {
      color: ${theme.colors.black};

      ${NavButtonStyles.StyledIcon} {
        fill: ${theme.colors.black};
      }
    }
    ${selected &&
    css`
      border: 1px solid ${theme.colors.grey.greenish};
      color: ${theme.colors.black};

      ${NavButtonStyles.StyledIcon} {
        fill: ${theme.colors.black};
      }
    `}

    ${disabled &&
    css`
      color: ${theme.colors.grey.grey50};
      cursor: default;

      &:hover {
        color: ${theme.colors.grey.grey50};
      }

      ${NavButtonStyles.StyledIcon} {
        fill: ${theme.colors.grey.grey50};
      }
    `}

    ${isTablet &&
    css`
      ${NavButtonStyles.StyledIcon} {
        margin-right: 0;
      }
    `}
  `
);
