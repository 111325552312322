import styled, { css } from "styled-components";
import { Tab as BaseTab, TabStyles } from "@diana-ui/tab";

const Tab = styled(BaseTab)(
  ({ theme }) => css`
    box-sizing: border-box;
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    margin: 0 ${theme.spaceUnit.sm};
    color: ${theme.colors.grey.grey100};
    border-bottom: 2px solid transparent;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-bottom: 2px solid ${theme.colors.grey.grey100};
    }

    &.selected {
      color: ${theme.colors.black};
    }

    &.disabled {
      pointer-events: none;
      color: ${theme.colors.grey.grey50};

      ${TabStyles.TabBorder} {
        background-color: ${theme.colors.grey.grey50};
      }
    }

    ${TabStyles.StyledLabel} {
      white-space: nowrap;
    }

    ${TabStyles.TabBorder} {
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      width: 100%;
      border-radius: 10px;
      background-color: ${theme.mode === "dark"
        ? theme.colors.white
        : theme.colors.black};
    }
  `
);

export { TabStyles };

export default Tab;
