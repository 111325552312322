import styled, { css } from "styled-components";
import theming from "styled-theming";
import {
  ImageAvatar as BaseImageAvatar,
  ImageAvatarStyles
} from "@diana-ui/avatar";
import Avatar from "./Avatar";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sizeStyle = theming.variants<any, any>("mode", "size", {
  xs: {
    light: css`
      width: 24px;
      height: 24px;
      border-radius: 6px;
    `
  },
  sm: {
    light: css`
      height: 62px;
      width: 62px;
      @media screen and (max-width: 700px) {
        height: 38px;
        width: 38px;
      }
    `
  },
  md: {
    light: css`
      height: 48px;
      width: 48px;
    `
  }
});

const ImageAvatar = styled(BaseImageAvatar).attrs({
  CustomAvatarComponent: Avatar
})(
  () => css`
    ${sizeStyle}

    ${ImageAvatarStyles.Image} {
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  `
);

ImageAvatar.displayName = "ImageAvatar";

export { ImageAvatarStyles };

export default ImageAvatar;
