import styled, { css } from "styled-components";
import { Portal } from "@diana-ui/portal";

export const StyledPortal = styled(Portal)``;

/**
 * Component container
 */
export const Container = styled.div(
  () => css`
    position: relative;

    &.disabled {
      pointer-events: none;
    }
  `
);

export const StyledPopover = styled.div(
  () => css`
    display: flex;
    z-index: 10;
  `
);

export const Overlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.background.overlay};
    z-index: 10;
  `
);

export const HeaderWrapper = styled.div(
  () => css`
    ${Container}.show-on-hover & {
      cursor: pointer;
    }
  `
);

export const Content = styled.div(
  () => css`
    width: 100%;
  `
);
