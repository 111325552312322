import styled, { css } from "styled-components";
import type { IDividerProps } from "./Divider";

/**
 * Component Wrapper Element
 */
export const Wrapper = styled.div<IDividerProps>(
  ({ theme, orientation }) => css`
    background-color: ${theme.colors.black};
    ${orientation === "horizontal"
      ? css`
          height: 1px;
          width: 100%;
        `
      : css`
          height: 100%;
          width: 1px;
        `};
  `
);
