import { RefObject } from "react";
import { useNotificationContext as useNotificationContextDiana } from "@diana-ui/notification";
import {
  ICustomNotification as INotification,
  ICustomNotificationStackRef as INotificationStackRef
} from "./NotificationStack";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotificationContext = (ref: RefObject<INotificationStackRef>) =>
  useNotificationContextDiana<INotificationStackRef, INotification>(ref);

export { default as Notification } from "./Notification";
export { default as NotificationStack } from "./NotificationStack";
export type {
  ICustomNotification as INotification,
  ICustomNotificationStackRef as INotificationStackRef
} from "./NotificationStack";
export * from "./NotificationContext";
