import React from "react";
import { IModalProps } from "@diana-ui/modal";
import { useIsMobile } from "@ddm-design-system/hooks";
import { StyledModal } from "./Modal.style";

export const Modal: React.FC<IModalProps> = props => {
  const isMobile = useIsMobile();
  return (
    <StyledModal
      isBottomSheet={isMobile}
      shouldCloseOnOverlayClick={props.isBottomSheet !== false && isMobile}
      {...props}
    />
  );
};

export default Modal;
