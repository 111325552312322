import styled, { css } from "styled-components";
import CloseableChip from "./CloseableChip";

export const ChipListWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const ChipContainer = styled.div(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xxs};
    margin-top: ${theme.spaceUnit.xs};
    margin-bottom: ${theme.spaceUnit.xs};
    :last-child {
      margin-right: 0;
    }
  `
);

export const StyledCloseableChip = styled(CloseableChip)``;
