import styled, { css } from "styled-components";
import { Icon } from "@diana-ui/icon";
import { ButtonText } from "@diana-ui/typography";
import type { IInputSpinnerProps } from "./InputSpinner";

/**
 * Component Container
 */
export const Container = styled.div(
  () => css`
    box-sizing: border-box;
    margin: 0;
  `
);

export const Value = styled.input<IInputSpinnerProps>(
  ({ theme }) => css`
    align-items: center;
    justify-content: center;
    min-width: 21px;

    ${theme.typography.body}

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  `
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buttonStyles = ({ disabled }: any) => css`
  outline: none;
  cursor: pointer;

  ${disabled &&
  css`
    cursor: initial;
  `}
`;

export const ButtonMin = styled.button<IInputSpinnerProps>(
  ({ disabled }) => css`
    ${buttonStyles({ disabled })}
  `
);

export const ButtonMinIcon = styled(Icon)(() => css``);

export const ButtonMinText = styled(ButtonText)(() => css``);

export const ButtonMax = styled.button<IInputSpinnerProps>(
  ({ disabled }) => css`
    ${buttonStyles({ disabled })}
  `
);

export const ButtonMaxIcon = styled(Icon)(() => css``);

export const ButtonMaxText = styled(ButtonText)(() => css``);
