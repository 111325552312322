import styled, { css } from "styled-components";
import { TertiaryButton } from "@ddm-design-system/button";
import { Icon } from "@ddm-design-system/icon";
import Modal from "./Modal";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";

export const StyledTertiaryButton = styled(TertiaryButton)``;

export const StyledTitleIcon = styled(Icon)``;

export const StyledModal = styled(Modal)(
  ({ theme }) => css`
    max-width: 600px;
    @media (max-width: 900px) {
      max-width: calc(100vw - ${theme.spaceUnit.xl});
      max-height: calc(100vh - ${theme.spaceUnit.xl});
    }
  `
);

export const StyledModalHeader = styled(ModalHeader)``;

export const StyledModalBody = styled(ModalBody)``;
