import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import { Popover } from "@ddm-design-system/popover";
import {
  Dropdown as BaseDropdown,
  DropdownStyles as BaseDropdownStyles,
  IItem,
  ISingleProps,
  PopoverStyles
} from "@diana-ui/dropdown";
import { FontWeight } from "@ddm-design-system/tokens";
// eslint-disable-next-line import/no-cycle
import * as DropdownStyles from "./Dropdown.style";

export const ICON_SIZE_PX = 16;

export interface IProps {
  isLabelOnTop?: boolean;
}

export type IDropdownProps = IProps & ISingleProps<IItem>;

const Dropdown = styled(BaseDropdown).attrs<IDropdownProps>(
  ({
    disabled,
    items,
    selectedItem,
    placeholder = "\u00A0", // non blocking space to force header line height,
    text,
    renderHeader
  }) => ({
    CustomPopoverComponent: Popover,
    isDisabled: disabled || items.length === 0,
    renderHeader:
      renderHeader ||
      ((isOpen: boolean): ReactElement => (
        <DropdownStyles.TextWrapper
          className={[isOpen && "open", disabled && "disabled"]
            .filter(Boolean)
            .join(" ")}
        >
          <DropdownStyles.Text>
            {text ?? (selectedItem ? selectedItem.text : placeholder)}
          </DropdownStyles.Text>
          <DropdownStyles.StyledIcon
            className={[isOpen && "open", disabled && "disabled"]
              .filter(Boolean)
              .join(" ")}
            name="ChevronDown"
            size={ICON_SIZE_PX}
          />
        </DropdownStyles.TextWrapper>
      ))
  })
)<IDropdownProps>(
  ({ theme, isLabelOnTop, disabled }) => css`
    background-color: ${theme.colors.white};
    border-radius: 4px 4px 0 0;

    ${isLabelOnTop &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}

    ${disabled &&
    css`
      ${DropdownStyles.TextWrapper} {
        color: ${theme.colors.grey.grey50};
      }

      ${DropdownStyles.StyledIcon} {
        stroke: ${theme.colors.grey.grey50};
      }

      ${BaseDropdownStyles.StyledLabel} {
        color: ${theme.colors.grey.grey50};
      }

      ${BaseDropdownStyles.StyledPopover} {
        ${BaseDropdownStyles.Item} {
          color: ${theme.colors.grey.grey50};
        }
      }
    `}

    ${BaseDropdownStyles.StyledLabel} {
      font-size: 18px;
      font-family: ${theme.fontFamily};
      margin-right: ${theme.spaceUnit.xs};
    }

    ${BaseDropdownStyles.StyledHeader} {
      min-width: 230px;
    }

    ${BaseDropdownStyles.Item} {
      background-color: ${theme.colors.white};
      cursor: pointer;
      padding: ${theme.spaceUnit.sm};
      height: 24px;
      display: flex;
      align-items: center;

      :hover {
        background-color: ${theme.colors.grey.grey25};
      }

      > * {
        width: 100%;
      }

      :last-child {
        border-radius: 0 0 10px 10px;
      }

      &.selected {
        font-weight: ${FontWeight.BOLD};
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    ${BaseDropdownStyles.ItemText} {
      display: block;
      font-size: 18px;
      font-family: ${theme.fontFamily};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ${BaseDropdownStyles.List} {
      border: 1px solid ${theme.colors.black};
      border-radius: 0 0 4px 4px;
      border-top: 1px solid ${theme.colors.grey.grey25};
      list-style: none;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 0;
      overflow-y: auto;
      text-align: start;
      width: 100%;
    }

    ${PopoverStyles.Container} {
      &.top {
        padding-top: 0;
        margin-top: 1px;
      }
    }

    ${PopoverStyles.HeaderWrapper} {
      &.top {
        ${DropdownStyles.TextWrapper} {
          &.open {
            border-top: 0;
            border-bottom: 1px solid;
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }

    ${PopoverStyles.StyledPopover} {
      &.top {
        ${BaseDropdownStyles.List} {
          border-radius: 4px 4px 0 0;
          border-top: 1px solid ${theme.colors.black};
          border-bottom: 1px solid ${theme.colors.grey.grey25};

          ${BaseDropdownStyles.Item} {
            :last-child {
              border-radius: 0;
            }
            :first-child {
              border-radius: 4px 4px 0 0;
            }
          }
        }
      }
    }
  `
);

export { DropdownStyles, BaseDropdownStyles };

export default Dropdown;
