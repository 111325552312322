import { createGlobalStyle } from "styled-components";

import BrandonGrotesqueBoldOtf from "./BrandonGrotesque-Bold.otf";
import BrandonGrotesqueBoldWoff2 from "./BrandonGrotesque-Bold.woff2";
import BrandonGrotesqueMediumOtf from "./BrandonGrotesque-Medium.otf";
import BrandonGrotesqueMediumWoff2 from "./BrandonGrotesque-Medium.woff2";
import BrandonGrotesqueRegularOtf from "./BrandonGrotesque-Regular.otf";
import BrandonGrotesqueRegularWoff2 from "./BrandonGrotesque-Regular.woff2";

export default createGlobalStyle`
    @font-face {
        font-family: 'BrandonGrotesque';
        src: url(${BrandonGrotesqueMediumWoff2}) format('woff2'),
        url(${BrandonGrotesqueMediumOtf}) format('otf');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'BrandonGrotesque';
        src: url(${BrandonGrotesqueRegularWoff2}) format('woff2'),
        url(${BrandonGrotesqueRegularOtf}) format('otf');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'BrandonGrotesque';
        src: url(${BrandonGrotesqueBoldWoff2}) format('woff2'),
        url(${BrandonGrotesqueBoldOtf}) format('otf');
        font-weight: bold;
        font-style: normal;
    }

    :root {
        font-size: 18px;

        body {
            font-family: BrandonGrotesque;
        }
    }
`;
