import { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    const updateSize = () => {
      if (typeof window !== undefined) {
        setSize([window.innerWidth, window.innerHeight]);
      }
    };

    if (typeof window !== undefined) {
      window.addEventListener("resize", updateSize);
    }

    updateSize();

    return () => {
      if (typeof window !== undefined) {
        window.removeEventListener("resize", updateSize);
      }
    };
  }, []);

  return size;
}
