import { Body } from "@diana-ui/typography";
import styled, { css } from "styled-components";
import type { IBaseChipProps } from "./BaseChip.types";

export const BaseChipWrapper = styled.div<IBaseChipProps>(
  ({ theme, onClick, selected, disabled }) => css`
    height: ${theme.spaceUnit.lg};
    padding: 0 ${theme.spaceUnit.xs};
    outline-style: none;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-color: ${theme.colors.grey.grey50};
    justify-content: space-between;

    ${onClick &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${theme.colors.grey.grey25};
      }
    `}

    ${selected &&
    css`
      background-color: ${theme.colors.grey.grey100};
    `}

    ${disabled &&
    css`
      pointer-events: none;
      color: ${theme.colors.grey.grey50};
    `}
  `
);

export const Text = styled(Body)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
